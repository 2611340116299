import { Layout } from 'antd';
import classnames from 'classnames';
import React from 'react';
import styles from './scanWrapper.less';

export interface ScanWrapperProps {
  className?: string;
  style?: React.CSSProperties;

  leftWidth: number;
  leftTitle: React.ReactNode;
  leftChildren?: React.ReactNode;

  rightTitle: React.ReactNode;
  rightChildren?: React.ReactNode;

  headerChildren?: React.ReactNode;
  headerStyle?: React.CSSProperties;
}

export const ScanWrapper: React.FC<ScanWrapperProps> = ({
  className = '',
  style = {},
  leftWidth,
  leftTitle,
  leftChildren,
  rightTitle,
  rightChildren,
  headerChildren,
  headerStyle = {},
}) => {
  return (
    <Layout
      className={classnames(styles.scanWrapper, className)}
      style={style}
    >
      {
        headerChildren ? (
          <Layout.Header style={headerStyle}>
            {headerChildren}
          </Layout.Header>
        ) : null
      }

      <Layout>
        <Layout.Sider
          theme="light"
          width={leftWidth}
        >
          <div className={styles.left}>
            <header className={styles.titleContainer}>
              <span className={styles.tag}>
                {leftTitle}
              </span>
            </header>
            <section className={styles.contentContainer}>
              {leftChildren}
            </section>
          </div>
        </Layout.Sider>
        <Layout.Content>
          <div className={styles.right}>
            <header className={styles.titleContainer}>
              {rightTitle}
            </header>
            <section className={styles.contentContainer}>
              {rightChildren}
            </section>
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
