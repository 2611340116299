import { Input, Select, Button, Space, Typography, Row } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { Store } from './store';
import { ScanWrapper } from '../../utils';
import { egeniePcTheme, thousandthSeparator } from 'egenie-common';

@observer
class HeaderContainer extends React.Component<{ store: Store; }> {
  render() {
    const {
      waveData,
      onDropdownVisibleChange,
      handleWaveValueChange,
      waveValue,
      isGetWaveData,
      getWaveData,
    } = this.props.store;

    return (
      <Space>
        <Select
          allowClear
          autoFocus
          defaultOpen
          filterOption={(inputValue: string, path) => {
            return [
              _.toString(path?.cartNo)
                .toLowerCase(),
              _.toString(path?.serialNo)
                .toLowerCase(),
            ].some((item) => item.includes(inputValue.toLowerCase()));
          }}
          onChange={handleWaveValueChange}
          onDropdownVisibleChange={onDropdownVisibleChange}
          options={waveData}
          placeholder="请选择波次号"
          showSearch
          style={{ width: 400 }}
          value={waveValue}
        />
        <Button
          className="ghost-bg-btn"
          loading={isGetWaveData}
          onClick={getWaveData}
        >
          刷新波次
        </Button>
      </Space>
    );
  }
}

@observer
class Left extends React.Component<{ store: Store; }> {
  render() {
    const {
      store: {
        barCode,
        handleBarCodeChange,
        inputRef,
        handleBarCodeEnterPress,
        statisticsCount,
        waveValue,
        scanSuccessData,
      },
    } = this.props;

    return (
      <>
        <h2>
          待烫印sku数量
        </h2>
        <div className={styles.count}>
          <a>
            {thousandthSeparator(statisticsCount?.unprocessedNumber)}
          </a>
        </div>
        <div className={styles.process}>
          <span>
            进度:&nbsp;
          </span>
          <Typography.Text ellipsis>
            {thousandthSeparator(statisticsCount?.stampedNumber)}
            /
            {thousandthSeparator(statisticsCount?.totalNumber)}
          </Typography.Text>
        </div>
        <h2 style={{ paddingBottom: egeniePcTheme.spacing['egenie-spacing-sm'] }}>
          请扫描商品条码
        </h2>
        <Input
          allowClear
          autoComplete="off"
          disabled={!waveValue}
          onChange={handleBarCodeChange}
          onPressEnter={(event: any) => handleBarCodeEnterPress(event.target.value)}
          placeholder="商品唯一码/SKU编码/条形码"
          ref={inputRef}
          size="large"
          value={barCode}
        />
        <div className={styles.scanNotification}>
          <Typography.Text
            copyable={false}
            ellipsis
            title={scanSuccessData?.message || ''}
          >
            {scanSuccessData?.message || ''}
          </Typography.Text>
        </div>
      </>
    );
  }
}

@observer
class Right extends React.Component<{ store: Store; }> {
  render() {
    const { store: { scanSuccessData }} = this.props;
    if (_.isEmpty(scanSuccessData)) {
      return null;
    }

    return (
      <div className={styles.rightContainer}>
        <div className={styles.boxContainer}>
          <Typography.Text
            copyable={false}
            ellipsis
            title={scanSuccessData?.cellNumber || ''}
          >
            {scanSuccessData?.cellNumber || ''}
          </Typography.Text>
        </div>
        <div className={styles.goodsContainer}>
          <img
            alt=""
            src={scanSuccessData?.hotStampPic}
            width="100%"
          />
          <section>
            <Typography.Title
              copyable={false}
              ellipsis
              level={1}
              title={scanSuccessData?.skuNo || ''}
            >
              {scanSuccessData?.skuNo || ''}
            </Typography.Title>
          </section>
        </div>
      </div>
    );
  }
}

@observer
class RightTitleContainer extends React.Component<{ store: Store; }> {
  render() {
    const {
      isPrinting,
      handlePrintUnicode,
    } = this.props.store;
    return (
      <Row
        justify="space-between"
        style={{ width: '100%' }}
      >
        <span>
          扫描结果
        </span>
        <Button
          loading={isPrinting}
          onClick={handlePrintUnicode}
          type="link"
        >
          重新打印
        </Button>
      </Row>
    );
  }
}

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <ScanWrapper
      headerChildren={<HeaderContainer store={store}/>}
      leftChildren={<Left store={store}/>}
      leftTitle="烫印"
      leftWidth={400}
      rightChildren={<Right store={store}/>}
      rightTitle={<RightTitleContainer store={store}/>}
    />
  );
}
