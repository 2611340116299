import type { InputRef } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import type { ScanResultData, StatisticsCount } from './types';
import { playVoice, voiceData } from '../../utils';
import type { Wave } from '../wavePickSorting/interface';
import { printHelper } from 'egenie-common';

export class Store {
  constructor() {
    this.getWaveData();
  }

  @observable public barCode = '';

  @action public handleBarCodeChange = (e) => {
    this.barCode = e.target.value;
  };

  @action public handleBarCodeEnterPress = async(barCode: string) => {
    this.barCode = '';
    if (!barCode) {
      playVoice(voiceData.请输入商品条码);
      return;
    }

    const info = await request<BaseData<ScanResultData>>({
      url: '/api/cloud/wms/rest/added/hot/stamp',
      method: 'post',
      data: {
        barCode,
        pickOrderId: this.waveValue,
        tempType: '27',
        tempId: 0,
      },
    });

    const data = info.data || {};
    if (data.processStatus) {
      playVoice(voiceData.di);
    } else if (data.message) {
      playVoice(data.message);
    }

    this.scanSuccessData = data;
    await this.handlePrintUnicode();
  };

  @observable public isPrinting = false;

  @action public handlePrintUnicode = async() => {
    if (this.scanSuccessData?.printData?.tempData && this.scanSuccessData?.printData?.userDataList) {
      printHelper.toggleToLodop();
      this.isPrinting = true;
      try {
        await printHelper.print({
          preview: false,
          contents: this.scanSuccessData?.printData?.userDataList,
          templateData: this.scanSuccessData?.printData?.tempData,
        });
      } finally {
        this.isPrinting = false;
      }
    }
  };

  public inputRef: React.RefObject<InputRef> = React.createRef<InputRef>();

  @observable public waveData: Wave['orderList'];

  @observable public waveValue: string;

  @observable.ref public scanSuccessData: ScanResultData = {};

  @observable public isGetWaveData = false;

  @action public getWaveData = () => {
    this.isGetWaveData = true;
    request<BaseData<Wave>>({ url: '/api/cloud/wms/rest/sort/query/cond' })
      .then((res) => {
        this.waveValue = undefined;
        this.statisticsCount = {};
        this.scanSuccessData = {};
        this.barCode = '';

        this.waveData = (res.data?.orderList || []).map((item) => ({
          ...item,
          value: `${item.wmsPickingOrderId}`,
          label: `${item.serialNo}${item.waveName}`,
        }));
      })
      .finally(() => this.isGetWaveData = false);
  };

  @action public handleWaveValueChange = (waveValue: string) => {
    this.waveValue = waveValue;
    if (waveValue) {
      this.getStatisticsCount();
    }
  };

  @action public onDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      setTimeout(() => {
        this.inputRef.current?.focus();
      });
    }
  };

  @observable public statisticsCount: StatisticsCount = {};

  @action public getStatisticsCount = () => {
    request<BaseData<StatisticsCount>>({
      url: '/api/cloud/wms/rest/added/hot/stamp/wave/info',
      method: 'post',
      data: { pickOrderId: this.waveValue },
    })
      .then((info) => this.statisticsCount = info.data || {});
  };
}

